import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const hasBaseURL = /^(http|https):/i.test(request.url);
		if (!hasBaseURL && request.url.indexOf('assets/icon') < 0 && request.url.indexOf('assets/i18n') < 0) {
			request = request.clone({ url: `${environment.apiEndpoint}${request.url}` });
		}

		return next.handle(request);
	}
}

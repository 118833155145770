import { provideHttpClient } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
	PreloadAllModules,
	provideRouter,
	withComponentInputBinding,
	withInMemoryScrolling,
	withPreloading,
} from '@angular/router';
import { provideFuse } from '@fuse';
import { appRoutes } from 'app/app.routes';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { mockApiServices } from 'app/mock-api';
import { CoreModule } from './core/core.module';
import { TranslocoHttpLoader } from './transloco-loader';
import { provideTransloco } from '@jsverse/transloco';

export const appConfig: ApplicationConfig = {
	providers: [
		provideAnimations(),
		provideHttpClient(),
		provideRouter(
			appRoutes,
			withPreloading(PreloadAllModules),
			withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
			withComponentInputBinding(),
		),

		// Material Date Adapter
		{
			provide: DateAdapter,
			useClass: LuxonDateAdapter,
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue: {
				parse: {
					dateInput: 'D',
				},
				display: {
					dateInput: 'DDD',
					monthYearLabel: 'LLL yyyy',
					dateA11yLabel: 'DD',
					monthYearA11yLabel: 'LLLL yyyy',
				},
			},
		},

		// Transloco Config
		provideTransloco({
			config: {
				availableLangs: [
					{
						id: 'en',
						label: 'English',
					},
					{
						id: 'vi',
						label: 'Tiếng Việt',
					},
				],
				defaultLang: 'en',
				fallbackLang: 'en',
				reRenderOnLangChange: true,
				prodMode: true,
			},
			loader: TranslocoHttpLoader,
		}),

		// Fuse
		provideAuth(),
		provideIcons(),
		provideFuse({
			mockApi: {
				delay: 0,
				services: mockApiServices,
			},
			fuse: {
				layout: 'classy',
				scheme: 'light',
				screens: {
					sm: '600px',
					md: '960px',
					lg: '1280px',
					xl: '1440px',
				},
				theme: 'theme-default',
				themes: [
					{
						id: 'theme-default',
						name: 'Default',
					},
					{
						id: 'theme-brand',
						name: 'Brand',
					},
					{
						id: 'theme-teal',
						name: 'Teal',
					},
					{
						id: 'theme-rose',
						name: 'Rose',
					},
					{
						id: 'theme-purple',
						name: 'Purple',
					},
					{
						id: 'theme-amber',
						name: 'Amber',
					},
				],
			},
		}),
		importProvidersFrom(CoreModule),
	],
};

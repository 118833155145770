import { ChangeDetectionStrategy, Component, DestroyRef, inject, ViewEncapsulation } from '@angular/core';
import { ActivationEnd, Router, RouterOutlet } from '@angular/router';
import { filter, map, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SettingFacade } from './core/store';
import { Languages } from './core/store/setting/setting.store';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: true,
	imports: [RouterOutlet],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
	#destroy = inject(DestroyRef);
	#router = inject(Router);
	#settingFacade = inject(SettingFacade);

	constructor() {
		this.#router.events
			.pipe(
				filter((event) => event instanceof ActivationEnd),
				map((event: ActivationEnd) => event.snapshot.queryParamMap.get('lang')),
				filter((lang: string) => !!lang && Object.values(Languages).includes(lang as Languages)),
				tap((lang: Languages) => this.#settingFacade.changeLanguage(lang)),
				takeUntilDestroyed(this.#destroy),
			)
			.subscribe();
	}
}

<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>
<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <router-outlet *ngIf="true"></router-outlet>
    </div>

</div>

import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { filter, of, switchMap } from 'rxjs';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
	const router: Router = inject(Router);
	const authService = inject(AuthService);

	return authService.check().pipe(
		switchMap((authenticated) => {
			if (!authenticated) {
				const redirectURL = state.url === '/login' ? '' : `redirectURL=${state.url}`;
				const urlTree = router.parseUrl(`?${redirectURL}`);
				return of(urlTree);
			}
			return of(true);
		}),
	);
};

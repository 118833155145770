import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

export const appRoutes: Route[] = [
	{
		path: '',
		pathMatch: 'full',
		loadComponent: () => import('app/modules/main/kyc/login/login.component').then((m) => m.LoginComponent),
	},
	{
		path: 'login',
		loadComponent: () => import('app/modules/main/kyc/login/login.component').then((m) => m.LoginComponent),
	},

	// Auth routes for authenticated users

	{
		path: '',
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		component: LayoutComponent,
		resolve: {
			initialData: initialDataResolver,
		},
		children: [
			{ path: 'kyc', loadChildren: () => import('app/modules/main/kyc/kyc.routes') },
			{
				path: 'identity',
				loadChildren: () => import('app/modules/main/identity/identity.routes'),
			},
		],
	},
	{
		path: '**',
		redirectTo: '',
	},
];

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
	#httpClient = inject(HttpClient);

	getTranslation = (lang: string): Observable<Translation> =>
		this.#httpClient.get<Translation>(`/assets/i18n/${lang}.json`);
}

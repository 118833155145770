import * as i0 from '@angular/core';
import { EventEmitter, Directive, Output, NgModule } from '@angular/core';
class ResizedEvent {
  constructor(newRect, oldRect) {
    this.newRect = newRect;
    this.oldRect = oldRect;
    this.isFirst = oldRect == null;
  }
}
class ResizedDirective {
  constructor(element, zone) {
    this.element = element;
    this.zone = zone;
    this.resized = new EventEmitter();
    this.observer = new ResizeObserver(entries => this.zone.run(() => this.observe(entries)));
  }
  ngOnInit() {
    this.observer.observe(this.element.nativeElement);
  }
  ngOnDestroy() {
    this.observer.disconnect();
  }
  observe(entries) {
    const domSize = entries[0];
    const resizedEvent = new ResizedEvent(domSize.contentRect, this.oldRect);
    this.oldRect = domSize.contentRect;
    this.resized.emit(resizedEvent);
  }
}
ResizedDirective.ɵfac = function ResizedDirective_Factory(t) {
  return new (t || ResizedDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
ResizedDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ResizedDirective,
  selectors: [["", "resized", ""]],
  outputs: {
    resized: "resized"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResizedDirective, [{
    type: Directive,
    args: [{
      selector: '[resized]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, {
    resized: [{
      type: Output
    }]
  });
})();
class AngularResizeEventModule {}
AngularResizeEventModule.ɵfac = function AngularResizeEventModule_Factory(t) {
  return new (t || AngularResizeEventModule)();
};
AngularResizeEventModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: AngularResizeEventModule
});
AngularResizeEventModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularResizeEventModule, [{
    type: NgModule,
    args: [{
      declarations: [ResizedDirective],
      imports: [],
      exports: [ResizedDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of angular-resize-event
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AngularResizeEventModule, ResizedDirective, ResizedEvent };
